<template>
  <div>
    <v-toolbar flat>
      <v-btn-toggle dense v-model="filterType" class="mr-4"
        ><v-btn text>Institutionen</v-btn
        ><v-btn text>Familien</v-btn></v-btn-toggle
      >
      <SchoolYearPicker outlined text v-model="schoolYear" />

      <v-spacer />
      <v-text-field
        single-line
        dense
        hide-details
        label="Suche"
        v-model="search"
        append-icon="mdi-magnify"
        clearable
      ></v-text-field>

      <v-btn text outlined color="info" class="ml-4" @click="showEmails"
        >Erinnern</v-btn
      >
    </v-toolbar>
    <v-card>
      <v-system-bar>{{ title }}</v-system-bar>
      <v-data-table
        :loading="loading"
        :items="itemsFiltered"
        :headers="headers"
        disable-filtering
        disable-sort
        disable-pagination
        hide-default-footer
        :custom-sort="sortInstitutions"
      >
        <template v-slot:item.comments="{ item }">
          <v-tooltip top v-if="item.comments">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-comment </v-icon>
            </template>
            <span>{{ item.comments }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.institution="{ item }">
          <InstitutionItem :value="item"></InstitutionItem>
        </template>
        <template v-slot:item.placements="{ item }">
          <span
            v-for="placement in item.placements"
            :key="'placement' + placement.id"
          >
            <PersonChip
              v-if="placement.student"
              :value="placement.student"
              close
              @click:close="removeStudent(placement)"
            />
            <v-chip
              v-else-if="placement.ignored"
              color="error"
              outlined
              close
              @click:close="deletePlacement(placement)"
              >ignoriert</v-chip
            >
            <v-chip
              v-else
              color="info"
              outlined
              close
              @click="showAdd(placement)"
              @click:close="deletePlacement(placement)"
              >freier Platz</v-chip
            >
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-right">
            <v-btn
              class="mr-2"
              v-if="!item.placements"
              small
              text
              outlined
              @click="ignore(item)"
              color="error"
              >ignorieren</v-btn
            >
            <v-btn
              v-if="
                !item.placements ||
                (item.placements.length == 1 && !item.placements[0].ignored)
              "
              small
              text
              outlined
              @click="add(item)"
              color="info"
              >hinzufügen</v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogEmail" max-width="800" scrollable>
      <v-card>
        <v-system-bar window
          >Erinnern <v-spacer /><v-btn icon @click="dialogEmail = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-system-bar>
        <v-alert type="info" tile text class="mb-0"
          >{{ emails.length }} können erinnert werden.
          {{ noEmails.length }} haben leider keine E-Mail-Adressse.
        </v-alert>

        <v-toolbar flat dense class="mt-0 mb-2">
          <v-tabs centered v-model="tabs">
            <v-tab>E-Mails</v-tab>
            <v-tab>keine E-Mails</v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card-text>
              <CopyButton :value="emails.join(',')"></CopyButton>
              {{ emails.join(", ") }}
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text>
              <CopyButton :value="noEmails.join(',')"></CopyButton>
              {{ noEmails.join(", ") }}
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialogEmail = false"> Schliessen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="400" scrollable>
      <v-card>
        <v-system-bar window
          >Schüler*in zuweisen <v-spacer /><v-btn icon @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-system-bar>
        <v-toolbar flat>
          <v-text-field
            single-line
            dense
            hide-details
            label="Schüler*in suchen"
            v-model="studentSearch"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </v-toolbar>

        <v-card-text class="pa-0 ma-0">
          <v-list>
            <PersonItemBasic
              v-for="student in lonelyStudentsFilter"
              :key="'student' + student.id"
              :value="student"
              @click="setStudent(student)"
            />
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog = false"> Abbrechen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CopyButton from "common/components/CopyButton.vue";
import InstitutionItem from "./InstitutionItem.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import PersonChip from "common/components/PersonChip.vue";
import SchoolYearPicker from "@/components/SchoolYearPicker.vue";

import { searchPerson, sortPeople } from "common/utils/people.js";

export default defineComponent({
  components: {
    CopyButton,
    InstitutionItem,
    PersonItemBasic,
    PersonChip,
    SchoolYearPicker,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogEmail: false,
      filterType: null,
      headers: [
        {
          text: "",
          value: "comments",
        },
        {
          text: "Institution",
          value: "institution",
        },
        {
          text: "Zuteilungen",
          value: "placements",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      items: [],
      lonelyStudents: [],
      placement: {},
      schoolYear: null,
      search: "",
      studentSearch: "",
      tabs: 0,
    };
  },
  computed: {
    emails() {
      return this.items
        .filter(
          (el) =>
            el.active &&
            !el.private &&
            (!el.placements || el.placements.length == 0) &&
            (el.email1 || el.email2)
        )
        .map((el) => (el.email1 ? el.email1 : el.email2));
    },
    noEmails() {
      return this.items
        .filter(
          (el) =>
            el.active &&
            !el.private &&
            (!el.placements || el.placements.length == 0) &&
            !el.email1 &&
            !el.email2
        )
        .map((el) => (el.instName ? el.instName : el.lastName1));
    },
    lonelyStudentsFilter() {
      return this.lonelyStudents.filter(
        (el) => !this.studentSearch || searchPerson(el, this.studentSearch)
      );
    },
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          (this.filterType == null ||
            (this.filterType == 0 && el.instName) ||
            (this.filterType == 1 && !el.instName)) &&
          (!this.search ||
            (el.placements &&
              el.placements.some(
                (el) => el.student && searchPerson(el.student, this.search)
              )) ||
            (el.instName &&
              el.instName.toLowerCase().includes(this.search.toLowerCase())) ||
            (el.firstName1 &&
              el.firstName1
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (el.lastName1 &&
              el.lastName1.toLowerCase().includes(this.search.toLowerCase())) ||
            (el.firstName2 &&
              el.firstName2
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (el.lastName2 &&
              el.lastName2.toLowerCase().includes(this.search.toLowerCase())) ||
            (el.town &&
              el.town.toLowerCase().includes(this.search.toLowerCase())))
      );
    },

    title() {
      let result = this.itemsFiltered.length + " Plätze ";
      switch (this.filterType) {
        case 0:
          result += "Institutionen";
          break;
        case 1:
          result += "Familien";
          break;

        default:
      }
      return result;
    },
  },
  watch: {
    schoolYear() {
      this.fetchData();
    },
  },
  methods: {
    async add(item) {
      await this.apiPost({
        resource: "placement/placement",
        data: {
          schoolYear: this.schoolYear.id,
          institution: item.id,
        },
      });
      this.fetchData();
    },
    async ignore(item) {
      await this.apiPost({
        resource: "placement/placement",
        data: {
          schoolYear: this.schoolYear.id,
          institution: item.id,
          ignoreInstitution: true,
        },
      });
      this.fetchData();
    },
    async removeStudent(placement) {
      if (
        await this.$root.confirm({
          message: `Soll diese Zuweisung entfernt werden?`,
          color: "error",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiPatch({
          resource: "placement/placement",
          id: placement.id,
          key: "student",
          value: 0,
        });
        this.fetchData();
      }
    },
    async setStudent(student) {
      await this.apiPatch({
        resource: "placement/placement",
        id: this.placement.id,
        key: "student",
        value: student.id,
      });
      this.dialog = false;
      this.fetchData();
    },

    sortInstitutions(items) {
      if (this.filterSpecial == 2) {
        return items.sort((a, b) => {
          const strA = `${100 - a.ignoreCount}${a.instName ? a.instName : ""}${
            a.lastName1 ? a.lastName1 : ""
          }${a.lastName2 ? a.lastName2 : ""}`;
          const strB = `${100 - b.ignoreCount}${b.instName ? b.instName : ""}${
            b.lastName1 ? b.lastName1 : ""
          }${b.lastName2 ? b.lastName2 : ""}`;
          return strA.localeCompare(strB, "de-CH");
        });
      }

      return items.sort((a, b) => {
        const strA = `${a.instName ? a.instName : ""}${
          a.lastName1 ? a.lastName1 : ""
        }${a.lastName2 ? a.lastName2 : ""}`;
        const strB = `${b.instName ? b.instName : ""}${
          b.lastName1 ? b.lastName1 : ""
        }${b.lastName2 ? b.lastName2 : ""}`;
        return strA.localeCompare(strB, "de-CH");
      });
    },
    async deletePlacement(placement) {
      if (
        await this.$root.confirm({
          message: `Soll dieser freie Platz gelöscht werden?`,
          color: "error",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "placement/placement",
          id: placement.id,
        });
        this.fetchData();
      }
    },
    showAdd(placement) {
      this.placement = placement;
      this.dialog = true;
    },
    showEmails() {
      this.dialogEmail = true;
    },
    async fetchData() {
      this.loading = true;
      const data = await this.apiList({
        resource: "placement/institution",
        query: "active=true",
      });
      const institutions = data
        .filter((el) => el.active)
        .sort((a, b) => {
          const strA = `${a.instName ? a.instName : ""}${
            a.lastName1 ? a.lastName1 : ""
          }${a.lastName2 ? a.lastName2 : ""}`;
          const strB = `${b.instName ? b.instName : ""}${
            b.lastName1 ? b.lastName1 : ""
          }${b.lastName2 ? b.lastName2 : ""}`;
          return strA.localeCompare(strB, "de-CH");
        });

      const students = await this.apiList({
        resource: "placement/student",
        query: "schoolYear=" + this.schoolYear.id,
      });

      const placements = await this.apiList({
        resource: "placement/placement",
        query: "schoolYear=" + this.schoolYear.id,
      });

      this.lonelyStudents = [];
      for (const student of students) {
        const placement = placements.find(
          (el) => el.student && el.student.id == student.id
        );
        if (!placement) {
          this.lonelyStudents.push(student);
        }
      }
      this.lonelyStudents = sortPeople(this.lonelyStudents);

      for (const placement of placements) {
        const institution = institutions.find(
          (el) => placement.institution && el.id == placement.institution.id
        );
        if (institution) {
          if (!("placements" in institution)) {
            institution["placements"] = [];
          }
          institution.placements.push(placement);
        }
      }

      this.items = institutions;
      this.loading = false;
    },
  },
  created() {},
});
</script>
